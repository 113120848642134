var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baseInfo-box"},[_c('div',{staticClass:"edit-title"},[_vm._v("任务项")]),_vm._v(" "),_c('div',{staticClass:"btnBox"},[(
        (_vm.basicInfo.serviceStatusCode == 1 ||
          _vm.basicInfo.serviceStatusCode == 2) &&
          _vm.basicInfo.hasRight == 1
      )?_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.handleOrder}},[_vm._v("\n      已处理\n    ")]):_vm._e(),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleChangeTask}},[_vm._v("\n      转工单\n    ")])],1),_vm._v(" "),_c('el-form',{staticClass:"form-content",attrs:{"label-width":"120","inline":true}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"问题类型:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.questionType)+"\n        ")])],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"回复时限:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.replyLimit)+"\n        ")])],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"具体项目:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.item)+"\n        ")])],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"问题内容:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.questionContent)+"\n        ")])],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"服务内容:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.serviceContent)+"\n        ")])],1),_vm._v(" "),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"处理结果:"}},[_vm._v("\n          "+_vm._s(_vm.detailInfo.content)+"\n        ")])],1)],1)],1),_vm._v(" "),[_c('el-table',{ref:"multipleTable",attrs:{"border":"","height":"300","data":_vm.tableData,"highlight-current-row":"","row-key":function (row) {
          return row.id;
        }}},[_c('el-table-column',{attrs:{"type":"selection","width":"50"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n            "+_vm._s(scope.$index +
                1 +
                (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n          ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"}})})],2),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],_vm._v(" "),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"转工单","visible":_vm.changeTaskVisible,"width":"450px","close-on-click-modal":false,"before-close":_vm.closeChange,"custom-class":"custom-dialog"}},[_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"work-order-list"},[[_c('div',{staticClass:"work-order-type"},[_vm._v("请选择要转工单的类型")]),_vm._v(" "),_c('div',{staticClass:"list-content"},_vm._l((_vm.taskList),function(item,index){return _c('div',{key:index,staticClass:"work-order"},[_c('div',{staticClass:"item",class:{
                  active: item.path == _vm.path
                },on:{"click":function($event){return _vm.clickItem(item.path)}}},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"til"},[_vm._v(_vm._s(item.title))])])}),0)]],2)]),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeChange}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toAddTask}},[_vm._v("确定")])],1)]),_vm._v(" "),_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":"处理","visible":_vm.handleVisible,"width":"450px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.handleClose,"custom-class":"custom-dialog"}},[_c('div',[_c('el-form',{ref:"handleForm",attrs:{"inline":true,"label-width":"120","model":_vm.handleForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"处理结果","prop":"content"}},[_c('el-input',{attrs:{"placeholder":"处理结果","maxlength":"100"},model:{value:(_vm.handleForm.content),callback:function ($$v) {_vm.$set(_vm.handleForm, "content", $$v)},expression:"handleForm.content"}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }