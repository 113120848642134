<!--
 * @Description: 对接申请
 * @Author: ChenXueLin
 * @Date: 2021-11-25 17:29:12
 * @LastEditTime: 2022-01-13 18:41:04
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="task-list-content">
      <detail-table-title @resize="handleTableTitleResize" :showSearch="false">
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns>
          <el-button
            type="primary"
            @click="handleDocking(1)"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >对接完成</el-button
          >
          <el-button
            type="primary"
            @click="handleDocking(2)"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >拒绝对接</el-button
          >
        </template>
      </detail-table-title>
      <!-- 表格 -->
      <el-table
        border
        height="300px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :row-key="
          row => {
            return row.sceneId;
          }
        "
      >
        <el-table-column
          type="selection"
          :selectable="checkSelectable"
          :reserve-selection="true"
          width="50"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'isChangeCard'">
              <e6-vr-select
                :data="isChangeList"
                :is-title="true"
                v-model="row.isChangeCard"
                clearable
                allow-create
              >
              </e6-vr-select>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 任务项 end -->
    <!--拒绝开通弹框  -->
    <el-dialog
      v-dialogDrag
      title="拒绝"
      :visible="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <!-- 拒绝原因 -->
      <el-form
        :inline="true"
        label-width="120"
        :model="refuseForm"
        :rules="refuseFormRules"
        ref="refuseForm"
      >
        <el-form-item label="拒绝原因" prop="remark">
          <el-input
            placeholder="拒绝原因"
            v-model="refuseForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { dockColumn } from "../../tableColumnData";
import { getDockService, handleDock } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "repairService",
  data() {
    return {
      tableData: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      columnData: dockColumn,
      total: 0,
      selColumn: [], //勾选的任务项
      /******对接完成/拒绝对接************/
      dockType: 1, //1完成2拒绝
      dialogVisible: false,
      refuseForm: {
        remark: "" //拒绝原因
      },
      refuseFormRules: {
        remark: [
          {
            required: true,
            message: "请输入拒绝原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["serviceId", "basicInfo"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    DetailTableTitle
  },
  watch: {},
  created() {
    this.getDockServiceReq();
  },
  methods: {
    //待处理状态才能进行处理
    checkSelectable(row) {
      return row.taskItemStatusCode == 1;
    },
    //查询对接任务项列表
    async getDockServiceReq() {
      try {
        let res = await getDockService({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        this.tableData = res.data.array;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //勾选任务项
    handleSelectionChange(val) {
      this.selColumn = val;
    },
    //点击对接完成/拒绝对接
    handleDocking(dockType) {
      this.dockType = dockType;
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      if (dockType == 1) {
        //完成
        this.handleDockReq();
      } else {
        //拒绝
        this.dialogVisible = true;
      }
    },
    //完成对接/拒绝对接 接口
    async handleDockReq() {
      try {
        let res = await handleDock({
          serviceId: this.serviceId,
          type: this.dockType,
          ...this.refuseForm,
          taskItemIds: this.selColumn.map(item => {
            return item.id;
          })
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.getDockServiceReq();
          this.handleClose();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //拒绝确认
    confirm() {
      this.$refs.refuseForm.validate(valid => {
        if (valid) {
          this.handleDockReq();
        }
      });
    },
    //关闭弹框
    handleClose() {
      this.refuseForm = {
        remark: ""
      };
      this.$refs.multipleTable.clearSelection();
      if (this.$refs.refuseForm) {
        this.$refs.refuseForm.resetFields();
      }
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    .title {
      margin-bottom: 15px;
    }
    .el-input {
      width: 280px;
    }
  }
}
</style>
