<!--
 * @Description: 场景变更任务项
 * @Author: ChenXueLin
 * @Date: 2021-11-23 17:28:14
 * @LastEditTime: 2022-01-13 18:40:24
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="task-list-content">
      <detail-table-title @resize="handleTableTitleResize" :showSearch="false">
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns>
          <el-button
            type="primary"
            @click="handleUpdate(1)"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >同意变更</el-button
          >
          <el-button
            type="primary"
            @click="handleUpdate(2)"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >拒绝变更</el-button
          >
        </template>
      </detail-table-title>
      <!-- 表格 -->
      <el-table
        border
        height="350px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :row-key="
          row => {
            return row.sceneId;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
          :selectable="checkSelectable"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 任务项 end -->
    <!-- 同意/拒绝变更弹框  -->
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <div>
        <div class="title">{{ title }}</div>
        <el-form
          :inline="true"
          label-width="120"
          :model="updateForm"
          :rules="updateFormRules"
          ref="updateForm"
        >
          <el-form-item label="备注" prop="remark">
            <el-input
              placeholder="备注"
              v-model="updateForm.remark"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { changeSceneColumn } from "../../tableColumnData";
import { getChangeService, agreeOrDisAgree } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "repairService",
  data() {
    return {
      tableData: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      dialogLoading: false,
      columnData: changeSceneColumn,
      total: 0,
      selColumn: [], //勾选的任务项
      /******同意/拒绝变更************/
      dialogTitle: "同意变更",
      updateType: 1, //1同意变更2拒绝变更
      title: "",
      dialogVisible: false,
      updateForm: {
        remark: ""
      },
      updateFormRules: {}
    };
  },
  mixins: [base, listPage, listPageReszie],
  props: ["serviceId", "basicInfo"],
  computed: {},
  components: {
    DetailTableTitle
  },
  watch: {
    updateType(val) {
      if (val == 2) {
        this.updateFormRules = {
          remark: [
            {
              required: true,
              message: "请输入拒绝原因",
              trigger: ["blur", "change"]
            }
          ]
        };
      } else {
        this.updateFormRules = {};
      }
    }
  },
  methods: {
    //查询场景名称变更任务项分页列表
    async getChangeServiceReq() {
      try {
        let res = await getChangeService({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        this.tableData = res.data.array;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //禁止同意变更或者拒绝变更选中
    checkSelectable(row) {
      return row.itemStatusCode == 1;
    },
    //勾选任务项
    handleSelectionChange(val) {
      this.selColumn = val;
    },
    //点击同意/拒绝变更
    handleUpdate(updateType) {
      this.updateType = updateType;
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      if (updateType == 1) {
        //同意
        this.dialogTitle = "同意变更";
        this.title = "是否确认同意变更所选安装终端的名称";
      } else {
        //拒绝
        this.dialogTitle = "拒绝变更";
        this.title = "是否确认拒绝变更所选安装终端的名称";
      }
      this.dialogVisible = true;
    },
    /*****同意/拒绝变更**********/
    confirm() {
      this.$refs.updateForm.validate(valid => {
        if (valid) {
          this.handleAgreeOrDisAgree();
        }
      });
    },
    //变更处理请求
    async handleAgreeOrDisAgree() {
      try {
        this.dialogLoading = true;
        let res = await agreeOrDisAgree({
          serviceId: this.serviceId,
          ...this.updateForm,
          type: this.updateType,
          taskItemIds: this.selColumn.map(item => {
            return item.id;
          })
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.getChangeServiceReq();
          this.handleClose();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.updateForm = {
        remark: ""
      };
      this.$refs.updateForm.resetFields();
      this.dialogVisible = false;
      this.selColumn = [];
      this.$refs.multipleTable.clearSelection();
    }
  },
  created() {
    this.getChangeServiceReq();
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    .title {
      margin-bottom: 15px;
    }
    .el-input {
      width: 280px;
    }
  }
}
</style>
