<!--
 * @Description: 咨询回复
 * @Author: ChenXueLin
 * @Date: 2021-11-25 17:29:12
 * @LastEditTime: 2022-05-11 16:24:54
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="baseInfo-box">
    <div class="edit-title">任务项</div>
    <div class="btnBox">
      <el-button
        type="primary"
        class="btn"
        @click="handleOrder"
        v-if="
          (basicInfo.serviceStatusCode == 1 ||
            basicInfo.serviceStatusCode == 2) &&
            basicInfo.hasRight == 1
        "
      >
        已处理
      </el-button>
      <el-button type="primary" @click="handleChangeTask">
        转工单
      </el-button>
    </div>

    <el-form label-width="120" class="form-content" :inline="true">
      <el-row>
        <el-col :span="8">
          <el-form-item label="问题类型:">
            {{ detailInfo.questionType }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="回复时限:">
            {{ detailInfo.replyLimit }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="具体项目:">
            {{ detailInfo.item }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="问题内容:">
            {{ detailInfo.questionContent }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务内容:">
            {{ detailInfo.serviceContent }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="处理结果:">
            {{ detailInfo.content }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template>
      <!-- 表格 -->
      <el-table
        border
        height="300"
        :data="tableData"
        highlight-current-row
        ref="multipleTable"
        :row-key="
          row => {
            return row.id;
          }
        "
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
    </template>
    <!-- 转工单弹框 -->
    <el-dialog
      v-dialogDrag
      title="转工单"
      :visible="changeTaskVisible"
      width="450px"
      :close-on-click-modal="false"
      :before-close="closeChange"
      custom-class="custom-dialog"
    >
      <div class="dialog-content">
        <div class="work-order-list">
          <template>
            <div class="work-order-type">请选择要转工单的类型</div>
            <div class="list-content">
              <div
                class="work-order"
                v-for="(item, index) in taskList"
                :key="index"
              >
                <div
                  class="item"
                  :class="{
                    active: item.path == path
                  }"
                  @click="clickItem(item.path)"
                >
                  <img :src="item.imgUrl" alt="" />
                </div>
                <div class="til">{{ item.title }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeChange">取消</el-button>
        <el-button type="primary" @click="toAddTask">确定</el-button>
      </div>
    </el-dialog>
    <!-- 点击处理 -->
    <el-dialog
      v-dialogDrag
      title="处理"
      :visible="handleVisible"
      width="450px"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <div>
        <el-form
          :inline="true"
          label-width="120"
          :model="handleForm"
          ref="handleForm"
          :rules="rules"
        >
          <el-form-item label="处理结果" prop="content">
            <el-input
              placeholder="处理结果"
              v-model="handleForm.content"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { getReplyService, handleReply, getReplyItems } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
import { replyColumn } from "../../tableColumnData";
export default {
  name: "repairService",
  data() {
    return {
      detailInfo: {},
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      columnData: replyColumn,
      total: 0,
      tableData: [],
      /****转工单*****/
      changeTaskVisible: false,
      taskList: [
        {
          title: "维修任务",
          type: 3,
          imgUrl: require("@/assets/images/weixiu.png"),
          path: "taskListManage/addRepairOrder"
        },
        {
          title: "重装任务",
          type: 6,
          imgUrl: require("@/assets/images/chongzhuang.png"),
          path: "taskListManage/addReinstallOrder"
        },

        {
          title: "巡检任务",
          type: 4,
          imgUrl: require("@/assets/images/xunjian.png"),
          path: "taskListManage/addInspectionOrder"
        }
      ],
      path: "",
      handleVisible: false,
      handleForm: {
        content: ""
      },
      dialogLoading: false,
      rules: {
        content: [
          {
            required: true,
            message: "请输入处理结果",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage],
  props: ["serviceId", "basicInfo"],
  computed: {},
  components: {},
  watch: {},
  created() {
    this.getReplyServiceReq();
    this.getReplyItemsReq();
  },
  methods: {
    //获取咨询回复服务单详情任务项
    async getReplyServiceReq() {
      try {
        let res = await getReplyService({
          serviceId: this.serviceId
        });
        this.detailInfo = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取任务项列表
    async getReplyItemsReq() {
      try {
        let res = await getReplyItems({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    //转工单
    handleChangeTask() {
      this.changeTaskVisible = true;
    },
    clickItem(path) {
      this.path = path;
    },

    //关闭转工单
    closeChange() {
      this.path = "";
      this.changeTaskVisible = false;
    },
    //点击确定转单
    toAddTask() {
      let ids = this.tableData.map(item => {
        let obj = {
          equipId: item.equipId,
          id: item.id
        };
        return obj;
      });
      let equipList = JSON.stringify(ids);
      this.routerPush({
        name: this.path,
        params: {
          refresh: true,
          equipList,
          serviceId: this.basicInfo.serviceId,
          queryType: 1
        }
      });
      this.closeChange();
    },
    /*****处理******/
    handleOrder() {
      this.handleVisible = true;
    },
    confirm() {
      this.$refs.handleForm.validate(valid => {
        if (valid) {
          this.hasHandle();
        }
      });
    },
    async hasHandle() {
      try {
        this.dialogLoading = true;
        this.$listeners.setLoading(true);
        let res = await handleReply({
          serviceId: this.serviceId,
          ...this.handleForm
        });
        if (res.code == "OK") {
          this.$message.success("已处理");
          this.handleClose();
          this.getReplyServiceReq();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭处理弹框
    handleClose() {
      this.handleVisible = false;
      this.$refs.handleForm.resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  .btnBox {
    display: flex;
    margin-left: 50px;
    margin-top: 20px;
    .btn {
      margin-right: 20px;
    }
  }
}
.form-content {
  margin-top: 20px;
  margin-left: 50px;
}
.dialog-content {
  padding: 20px;
  box-sizing: border-box;
  .input-with-select {
    width: 240px;
  }
  .work-order-list {
    .work-order-type {
      font-size: 14px;
      color: #444446;
      // font-weight: 550;
    }
    .list-content {
      display: flex;
      flex-wrap: wrap;
      .work-order {
        margin-right: 10px;
        .item {
          width: 89px;
          height: 89px;
          border-radius: 5px;
          border: 1px solid #eae9ee;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          & > img {
            width: 40px;
            height: 37px;
          }
        }
        .active {
          border: 1px solid #46bfea;
        }
        .til {
          text-align: center;
          font-size: 14px;
          color: #676769;
          margin-top: 10px;
        }
      }
    }
  }
}
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
