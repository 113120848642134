<!--
 * @Description: 服务单详情---》基本信息页面
 * @Author: ChenXueLin
 * @Date: 2021-10-13 14:42:40
 * @LastEditTime: 2022-04-19 16:20:01
 * @LastEditors: ChenXueLin
-->
<!-- 基本信息 -->
<template>
  <div>
    <!-- 基本信息 start -->
    <div class="baseInfo-box basicForm">
      <div class="edit-title" style="margin-top:10px;">
        <div>基本信息</div>
        <i
          style="cursor: pointer;"
          class="e6-icon-arrow-b_line"
          title="展开"
          v-if="!isFold"
          @click="handleFold(true)"
        ></i>
        <i
          style="cursor: pointer;"
          class="e6-icon-arrow-t_line"
          title="收起"
          v-else
          @click="handleFold(false)"
        ></i>
      </div>
      <el-form label-width="100px" class="editForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="服务单号">
              {{ basicInfo.serviceNo }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务单类型">
              {{ basicInfo.serviceType }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-width="100px" :class="isFold ? '' : 'mbForm'">
        <el-row>
          <el-col :span="8">
            <el-form-item label="客户名称">
              {{ basicInfo.corpName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属主体">
              {{ basicInfo.mainPart }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人">
              {{ basicInfo.contactName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              {{ basicInfo.contactPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系地址">
              {{ basicInfo.address }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="要求完成时间">
              {{ basicInfo.expectTimeStr }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注">
              {{ basicInfo.remark }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前处理人">
              {{ basicInfo.nowHandleBy }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="完成时间">
              {{ basicInfo.finishTimeStr }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-width="100px" v-if="isFold" class="mbForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="业务员">
              {{ basicInfo.salesman }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务员电话">
              {{ basicInfo.salesmanPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建人">
              {{ basicInfo.createdBy }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间">
              {{ basicInfo.createdTime }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 基本信息 end -->
    <!-- 维修服务单任务项 -->
    <repair-service
      v-if="basicInfo.serviceTypeCode == 1"
      :serviceId="basicInfo.serviceId"
      :corpId="basicInfo.corpId"
      :corpName="basicInfo.corpName"
      :basicInfo="basicInfo"
    ></repair-service>
    <!-- 场景变更任务项 -->
    <change-scene
      v-if="basicInfo.serviceTypeCode == 2"
      :serviceId="basicInfo.serviceId"
      :corpName="basicInfo.corpName"
      :basicInfo="basicInfo"
    ></change-scene>
    <!-- 开通申请任务项 -->
    <open
      v-if="basicInfo.serviceTypeCode == 3"
      :serviceId="basicInfo.serviceId"
      :corpId="basicInfo.corpId"
      :corpName="basicInfo.corpName"
      :basicInfo="basicInfo"
    ></open>
    <!-- 报停申请任务项 -->
    <Stop
      v-if="basicInfo.serviceTypeCode == 4"
      :serviceId="basicInfo.serviceId"
      :corpId="basicInfo.corpId"
      :corpName="basicInfo.corpName"
      :basicInfo="basicInfo"
    ></Stop>
    <!-- 设置打印信息 -->
    <set-print
      v-if="basicInfo.serviceTypeCode == 5"
      :serviceId="basicInfo.serviceId"
      :basicInfo="basicInfo"
    ></set-print>
    <!-- 对接 -->
    <docking
      v-if="basicInfo.serviceTypeCode == 6"
      :serviceId="basicInfo.serviceId"
      :basicInfo="basicInfo"
    ></docking>
    <!-- 咨询回复 -->
    <reply
      v-if="basicInfo.serviceTypeCode == 7"
      :serviceId="basicInfo.serviceId"
      :basicInfo="basicInfo"
      v-on="$listeners"
    ></reply>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import RepairService from "./tableComponent/repairService.vue";
import ChangeScene from "./tableComponent/changeScene.vue";
import Open from "./tableComponent/open.vue";
import Stop from "./tableComponent/stop.vue";
import SetPrint from "./tableComponent/setPrint.vue";
import Docking from "./tableComponent/docking.vue";
import reply from "./tableComponent/reply.vue";
import { getServiceBasicInfo } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "",
  data() {
    return {
      serviceTypeCode: "", //1维修2场景变更3开通申请4报停申请5打印申请6对接申请7咨询回复申请
      taskId: "", //任务单id
      recordsList: [1, 2],
      isFold: false //是否展开
    };
  },
  props: ["basicInfo"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    RepairService,
    ChangeScene,
    Open,
    Stop,
    SetPrint,
    Docking,
    reply
  },
  watch: {},
  methods: {
    //获取基本信息
    async getServiceBasicInfoReq() {
      try {
        this.loading = true;
        let res = await getServiceBasicInfo({
          serviceId: this.serviceId
        });
        //基本信息
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
        if (this.basicInfo.tags.length) {
          this.tags = this.basicInfo.tags.split(",");
        } else {
          this.tags = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleFold(isFold) {
      console.log(isFold, 1111);
      this.isFold = isFold;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "../../../../../../assets/styles/index.scss";
.baseInfo-content-wrap {
  display: flex;
  width: 100%;
  // height: 100%;
  position: relative;
  .detailInfo-content {
    width: calc(100% - 300px);
    height: 100%;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .task-list-content {
    .table-title-btn {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 50px;
      font-size: 14px;
      box-sizing: border-box;
      color: #303133;
      border-width: 1px 0;
      border-style: solid;
      border-color: #f5f7fa;
      background-color: #f9fafc;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      i {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: $--color-primary;
        font-size: 12px;
        line-height: 28px;
        font-style: normal;
        background-color: $--color-primary-light-8; // #daf2fb
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      [class^="e6-icon"] {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 16px;
      }
    }
    .search-list {
      background: #fff !important;
    }
    .label-button-box {
      width: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
    }
  }
  .record-box {
    width: 300px;
    height: 100%;
    padding: 10px;
    // max-height: 550px;
    overflow-y: auto;
    box-sizing: border-box;
    .switch-btn {
      display: flex;
      & > div {
        color: #6d7075;
      }
      & > div:nth-child(1) {
        margin-right: 30px;
      }
      .active {
        color: #48c0e5;
        font-weight: 600;
        &:after {
          content: "";
          display: block;
          width: 65px;
          height: 3px;
          background: #48c0e5;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
