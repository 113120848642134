<!--
 * @Description: 报停申请
 * @Author: ChenXueLin
 * @Date: 2021-11-25 15:58:30
 * @LastEditTime: 2022-05-11 17:28:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="task-list-content">
      <detail-table-title @resize="handleTableTitleResize" :showSearch="false">
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns>
          <el-button
            type="primary"
            @click="handleAgreeStop()"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >同意报停</el-button
          >
          <el-button
            type="primary"
            @click="refusedStop(2)"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >拒绝报停</el-button
          >
        </template>
      </detail-table-title>
      <!-- 表格 -->
      <el-table
        border
        height="300px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :row-key="
          row => {
            return row.id;
          }
        "
      >
        <el-table-column
          type="selection"
          :selectable="checkSelectable"
          :reserve-selection="true"
          width="50"
        ></el-table-column>
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'sceneName'">
              {{ row.show ? row[column.fieldName] : "" }}
            </span>
            <span v-else-if="column.fieldName === 'sceneType'">
              {{ row.show ? row[column.fieldName] : "" }}
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 任务项 end -->
    <!--拒绝开通弹框  -->
    <el-dialog
      v-dialogDrag
      title="拒绝报停"
      :visible="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <!-- <el-form
        :inline="true"
        label-width="120"
        :model="stopForm"
        :rules="stopFormRules"
        ref="stopForm"
        v-if="stopType == 1"
      >
        <el-form-item label="报停原因" prop="stopReason">
          <el-input
            placeholder="报停原因"
            v-model="stopForm.stopReason"
          ></el-input>
        </el-form-item>
      </el-form> -->
      <!-- 拒绝原因 -->
      <el-form
        :inline="true"
        label-width="120"
        :model="refuseForm"
        :rules="refuseFormRules"
        ref="refuseForm"
      >
        <el-form-item label="拒绝原因" prop="remark">
          <el-input
            placeholder="拒绝原因"
            v-model="refuseForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { stopColumn } from "../../tableColumnData";
import { getStopService, agreeStop } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "repairService",
  data() {
    return {
      tableData: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      columnData: stopColumn,
      total: 0,
      selColumn: [], //勾选的任务项
      /******同意/拒绝报停************/
      agreeList: [], //需要换卡的任务项
      refusedList: [], //不需要换卡的任务项
      stopType: 1, //1同意2拒绝
      dialogVisible: false,
      stopForm: {
        stopReason: "" //报停原因
      },
      stopFormRules: {
        stopReason: [
          {
            required: true,
            message: "请输入报停原因",
            trigger: ["blur", "change"]
          }
        ]
      },
      refuseForm: {
        remark: "" //拒绝原因
      },
      refuseFormRules: {
        remark: [
          {
            required: true,
            message: "请输入拒绝原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["serviceId", "corpId", "corpName", "basicInfo"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    DetailTableTitle
  },
  watch: {},
  created() {
    this.getStopServiceReq();
  },
  methods: {
    //待处理状态才能进行处理
    checkSelectable(row) {
      return row.itemStatusCode == 1;
      //  || row.itemStatusCode == 4;
    },
    //查询报停任务项列表
    async getStopServiceReq() {
      try {
        let res = await getStopService({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        let tableData = res.data.array;
        for (let i = 0; i < tableData.length; i++) {
          if (i == 0) {
            tableData[i].show = true;
          } else {
            if (tableData[i].sceneName == tableData[i - 1].sceneName) {
              tableData[i].show = false;
            } else {
              tableData[i].show = true;
            }
          }
        }
        this.tableData = tableData;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //勾选任务项
    handleSelectionChange(val) {
      this.selColumn = val;
    },
    //点击同意报停
    handleAgreeStop() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      //需要拆机的数据
      let removeOrderList = this.selColumn.filter(item => {
        return item.cardTreatment == "是";
      });
      //不需要拆机的数据
      let stopList = this.selColumn.filter(item => {
        return item.cardTreatment == "否";
      });
      if (removeOrderList.length) {
        let ids = removeOrderList.map(item => {
          let obj = {
            equipId: item.equipId,
            id: item.id
          };
          return obj;
        });
        let equipList = JSON.stringify(ids);
        this.routerPush({
          name: "taskListManage/addRemoveOrder",
          params: {
            refresh: true,
            equipList,
            serviceId: this.basicInfo.serviceId,
            isStop: 1,
            queryType: 1
          }
        });
      }
      if (stopList.length) {
        console.log(stopList, "====");
        this.stopList = stopList;
        this.handleStop(1);
      }
    },
    //拒绝报停
    refusedStop() {
      this.dialogVisible = true;
    },
    /*****同意/拒绝变更**********/
    confirm() {
      this.$refs.refuseForm.validate(valid => {
        if (valid) {
          this.handleStop(2);
        }
      });
    },
    //拒绝报停
    async handleStop(type) {
      try {
        let selColumnIds;
        if (type == 1) {
          selColumnIds = this.stopList.map(item => {
            return item.id;
          });
        } else {
          selColumnIds = this.selColumn.map(item => {
            return item.id;
          });
        }
        let res = await agreeStop({
          serviceId: this.serviceId,
          ...this.refuseForm,
          type,
          taskItemIds: selColumnIds
        });
        if (res.code == "OK") {
          if (type == 2) {
            this.$message.success("操作成功");
            this.$refs.multipleTable.clearSelection();
            this.selColumn = [];
            this.handleClose();
            this.getStopServiceReq();
            eventBus.$emit("refresh");
          } else {
            this.$message.success("操作成功");
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.refuseForm = {
        remark: ""
      };
      // this.stopForm = {
      //   stopReason: ""
      // };
      this.$refs.refuseForm.resetFields();
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    .title {
      margin-bottom: 15px;
    }
    .el-input {
      width: 280px;
    }
  }
}
</style>
